/* Grisa Style */
/* Sass Variable */

// $grey: #505050
// $dark_grey: #282828
// $link-color: #a0a0a0
// .login-page
//   background-color: white
//   .wrapper-login
//     padding-top: 100px
//     padding-bottom: 100px
//     h3
//       font-size: 12px
//       color: $dark_grey
//       text-transform: uppercase
//       font-weight: 700
//       margin-bottom: 20px
//   input#terms
//     position: relative
//     top: 1px /* Fixed position checkbox (Terms of use) */
//   label
//     margin-left: 10px
//     a
//       color: $link-color
//       text-transform: uppercase
//   a.link-news
//     margin-top: 50px
//   a.btn
//     padding-top: 10px
//     padding-bottom: 10px
//     width: 100%
//   .list-buttons
//     margin-top: 80px
//     .btn
//       width: 100%
//   .login-form /* User Login Form | User Profile Form | User Anonymous Form */
//     #user-login-form,
//     #user-login,
//     #user-profile-form
//       .form-item
//         width: 100%
//         display: table
//         label[for="edit-name"],
//         label[for="edit-pass"],
//         label[for="edit-current-pass"],
//         label[for="edit-mail"],
//         label[for="edit-pass-pass1"],
//         label[for="edit-pass-pass2"]
//           display: table-cell
//           width: 45%
//           font-size: 12px
//           color: $dark_grey
//           text-transform: uppercase
//           margin-left: auto
//           font-weight: 700
//           vertical-align: middle
//           padding-right: 5%
//         input#edit-name,
//         input#edit-pass,
//         input#edit-current-pass,
//         input#edit-mail,
//         input#edit-pass-pass1,
//         input#edit-pass-pass2
//           display: table-cell
//       button.form-submit
//         background-color: $dark_grey
//         color: white
//         font-weight: 700
//         width: 50%
//         float: right
//         border-radius: 5px
//     #user-profile-form
//       button.form-submit
//         width: auto
//         float: none
//     .check-offers
//       margin-top: 80px
//       h3
//         margin-bottom: 12px
//         margin-top: 12px
//       button
//         background-color: $dark_grey
//         padding: 12px 12px
//         width: 100%
//         span
//           display: block
//           top: 0
// /* Fixes Rewrite style: Login | Register | Password */
// .template-user /* Rewrite style on template user */
//   background-color: transparent
//   .login-page,
//   .register-page,
//   .recovery-password
//     margin-top: 110px
//     margin-bottom: 80px
